import React from "react";
import { isMobile, isSafari } from "react-device-detect";
import TimeTable, { Event, Events, EventType } from "../components/TimeTable";
import { LocaleConfig } from "../localization";
import {
    Utils,
    MenuItemClient,
    ClientInfoInterface,
    WeekItem,
    AgendaItem,
    WorkingHoursDay,
    AdditionalServiceItemClientCounted,
    DiscountCampaignItem,
    BookingRulesItem,
} from "visit-shared";
import Network from "../network";
import ConfirmDialog from "../components/ConfirmDialog";
import { Backdrop, Box, CircularProgress, makeStyles } from "@material-ui/core";
import SideButton from "../components/SideButton";
import { useSwipeable } from "react-swipeable";
import { fadeIn, fadeOut } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { Dimensions, userUITheme } from "../style/theme";
import ArrowBackOutlined from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import CachedOutlined from "@material-ui/icons/CachedOutlined";
import { TextInputItem } from "../components/TextInput";

interface FreeTime {
    date: Date;
    timeStartMin: number;
    timeEndMin: number;
}

interface FreeTimeMap {
    monday: FreeTime[];
    tuesday: FreeTime[];
    wednesday: FreeTime[];
    thursday: FreeTime[];
    friday: FreeTime[];
    saturday: FreeTime[];
    sunday: FreeTime[];
}

interface TimeTableProps {
    dimensions: Dimensions;
    onClose: any;
    menuItems: MenuItemClient[];
    additionalServices: AdditionalServiceItemClientCounted[];
    replaceAgendaItem: AgendaItem | null;
    userData: ClientInfoInterface;
    autoFillName: string;
    autoFillMobile: string;

    bookingRulesItems: BookingRulesItem[];
    discountCampaignItems: DiscountCampaignItem[];
    selectedDiscountCampaignItem: DiscountCampaignItem | null;
    updateDiscountsData: any;
    updatePromoCode: any;
    updateSelectedDiscountCampaign: any;
    selectedDiscountCampaignItemId: number;
    promoCode: TextInputItem;
    promoCodeInfoText: string;
}

const animationStyles = StyleSheet.create({
    fadeIn: {
        animationName: fadeIn,
        animationDuration: isMobile ? "2s" : "1s",
    },
    fadeOut: {
        animationName: fadeOut,
        animationDuration: "1s",
    },
});

const useStyles = makeStyles((theme) => ({
    sliderButton: {
        position: "absolute",
        top: "60%",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        borderWidth: 1,
        borderRadius: "50%",
        boxShadow: `0px 0px 5px 2px ${theme.palette.primary.main}`,
    },
    sliderButtonSafari: {
        position: "absolute",
        top: "60%",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        borderWidth: 3,
        borderRadius: "50%",
        borderColor: theme.palette.primary.main,
        borderStyle: "solid",
    },
}));

const sliderButtonInitialPos = -40;

export default function TimeTableScreen({
    dimensions,
    onClose,
    menuItems,
    additionalServices,
    replaceAgendaItem,
    userData,
    autoFillName,
    autoFillMobile,

    bookingRulesItems,
    discountCampaignItems,
    selectedDiscountCampaignItem,
    updateDiscountsData,
    updatePromoCode,
    updateSelectedDiscountCampaign,
    selectedDiscountCampaignItemId,
    promoCode,
    promoCodeInfoText,
}: TimeTableProps) {
    const browserBackButtonHandlingInit = React.useRef(false);
    React.useEffect(() => {
        if (!browserBackButtonHandlingInit.current) {
            browserBackButtonHandlingInit.current = true;
            // In case of few navigations creates to many states. Can this be handled better?
            window.history.pushState(null, "", window.location.href);
        }

        window.onpopstate = () => {
            backToMenu(false);
        };

        return () => {
            window.onpopstate = null;
        };
    });

    const classNames = useStyles();

    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.fadeIn
    );
    const [leftButtonPosition, setLeftButtonPosition] = React.useState(
        sliderButtonInitialPos
    );
    const [rightButtonPosition, setRightButtonPosition] = React.useState(
        sliderButtonInitialPos
    );
    const [updateButton, setUpdateButton] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(true);

    const hasBeenCalled = React.useRef(false);
    const eventCounter = React.useRef(1);
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const nowDate = React.useRef(new Date());
    const timeTableStart = React.useRef(0);
    const timeTableEnd = React.useRef(24);
    const weekStart = React.useRef(new Date());
    const weekEnd = React.useRef(new Date());
    const [events, setEvents] = React.useState<Events>({
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    });

    const selectedEvent = React.useRef<Event | undefined>();
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

    function setWeekBoundaries(date: Date) {
        let tempDate = new Date(date);
        let day = tempDate.getDay() || 7;
        if (day !== 1) tempDate.setHours(-24 * (day - 1));
        weekStart.current = new Date(tempDate);
        tempDate.setHours(24 * 6);
        weekEnd.current = new Date(tempDate);
    }

    const getTotalProcedureDuration = () => {
        let duration = menuItems.reduce((a, b) => a + b.durationMins, 0);
        duration += additionalServices.reduce(
            (a, b) => (a += b.durationMins * b.count),
            0
        );

        // Taking into account appointment additional time settings
        duration += userData.apptAddTimeMin;

        return duration;
    };

    const constructor = () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        setCurrentAnimation(animationStyles.fadeIn);
        setIsLoading(true);
        setWeekBoundaries(currentDate);
        loadItems();
    };

    const getBookingStartTime = () => {
        const timeMin =
            nowDate.current.getHours() * 60 +
            nowDate.current.getMinutes() +
            userData.minTimeToAptMin;

        return timeMin > timeTableEnd.current * 60
            ? timeTableEnd.current * 60
            : timeMin;
    };

    const renderEvent = ({ event, defaultAttributes }) => {
        let startTime = event.startTime;
        let endTime = event.endTime;
        if (Number(event.type!) === EventType.eFree) {
            endTime = new Date(event.startTime.toISOString());
            endTime.setMinutes(
                endTime.getHours() * 60 +
                    endTime.getMinutes() +
                    getTotalProcedureDuration()
            );
        }
        return (
            <div
                {...defaultAttributes}
                title={event.name}
                key={event.id}
                onClick={() => {
                    if (Number(event.type) === EventType.eFree) {
                        selectedEvent.current = event;
                        setShowConfirmDialog(true);
                    }
                }}
            >
                {Number(event.type) === EventType.eFree ? (
                    <span>
                        {Utils.DurationToTimeString(
                            startTime.getHours() * 60 + startTime.getMinutes()
                        )}
                    </span>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    const getEventDay = (
        dayMap: Events | FreeTimeMap,
        dayNumber: number
    ): Event[] | FreeTime[] => {
        let dayOfTheWeek;
        switch (dayNumber) {
            case 0:
                dayOfTheWeek = dayMap.sunday;
                break;
            case 1:
                dayOfTheWeek = dayMap.monday;
                break;
            case 2:
                dayOfTheWeek = dayMap.tuesday;
                break;
            case 3:
                dayOfTheWeek = dayMap.wednesday;
                break;
            case 4:
                dayOfTheWeek = dayMap.thursday;
                break;
            case 5:
                dayOfTheWeek = dayMap.friday;
                break;
            case 6:
            default:
                dayOfTheWeek = dayMap.saturday;
                break;
        }
        return dayOfTheWeek;
    };

    const addEvent = (
        dayOfTheWeek: Event[],
        date: Date,
        startMin: number,
        endMin: number,
        type: EventType
    ) => {
        let startTime = new Date(date.toISOString());
        startTime.setMinutes(startMin);
        let endTime = new Date(date.toISOString());
        endTime.setMinutes(endMin);
        dayOfTheWeek.push({
            id: eventCounter.current++,
            name: "",
            type: type,
            startTime: startTime,
            endTime: endTime,
        });
    };

    const addFreeTimeBreak = (
        dayOfTheWeek: FreeTime[],
        date: Date,
        startMin: number,
        endMin: number
    ) => {
        for (let i = 0; i < dayOfTheWeek.length; ++i) {
            if (
                dayOfTheWeek[i].timeStartMin <= startMin &&
                dayOfTheWeek[i].timeEndMin >= endMin
            ) {
                // Break inside free time
                if (dayOfTheWeek[i].timeStartMin !== startMin)
                    dayOfTheWeek.push({
                        date,
                        timeStartMin: dayOfTheWeek[i].timeStartMin,
                        timeEndMin: startMin,
                    });
                if (dayOfTheWeek[i].timeEndMin !== endMin)
                    dayOfTheWeek.push({
                        date,
                        timeStartMin: endMin,
                        timeEndMin: dayOfTheWeek[i].timeEndMin,
                    });
                dayOfTheWeek.splice(i, 1);
                return;
            }
            if (
                dayOfTheWeek[i].timeStartMin <= startMin &&
                startMin <= dayOfTheWeek[i].timeEndMin
            ) {
                // Remove free time tail
                if (dayOfTheWeek[i].timeStartMin === startMin) {
                    dayOfTheWeek.splice(i, 1);
                    --i;
                } else {
                    dayOfTheWeek[i].timeEndMin = startMin;
                }
            }
            if (
                i >= 0 &&
                dayOfTheWeek[i].timeStartMin <= endMin &&
                endMin <= dayOfTheWeek[i].timeEndMin
            ) {
                // Remove free time front
                if (dayOfTheWeek[i].timeEndMin === endMin) {
                    dayOfTheWeek.splice(i, 1);
                    --i;
                } else {
                    dayOfTheWeek[i].timeStartMin = endMin;
                }
            }

            if (
                i >= 0 &&
                dayOfTheWeek[i].timeStartMin >= startMin &&
                dayOfTheWeek[i].timeEndMin <= endMin
            ) {
                // Delete free time slot
                dayOfTheWeek.splice(i, 1);
                --i;
            }
        }
    };

    const loadWorkingHours = async (
        newEvents: Events,
        freeTime: FreeTimeMap,
        data: WorkingHoursDay[]
    ): Promise<Events> => {
        // Set time table start end
        let earliestStart = 1440,
            latestEnd = 0;
        for (let i = 0; i < data.length; ++i) {
            if (data[i].hours.end !== 0) {
                if (earliestStart > data[i].hours.start)
                    earliestStart = data[i].hours.start;
                if (latestEnd < data[i].hours.end)
                    latestEnd = data[i].hours.end;
            }
        }
        timeTableStart.current = earliestStart / 60;
        timeTableEnd.current =
            latestEnd % 60 > 0 ? latestEnd / 60 + 1 : latestEnd / 60;
        // --------------------------

        const tableStartMin = timeTableStart.current * 60;
        const tableEndMin = timeTableEnd.current * 60;
        for (let i = 0; i < data.length; ++i) {
            let dayOfTheWeek: Event[] = getEventDay(
                newEvents,
                data[i].dayId
            ) as Event[];
            let freeTimeOfTheWeek: FreeTime[] = getEventDay(
                freeTime,
                data[i].dayId
            ) as FreeTime[];
            let date = new Date(weekStart.current);
            date.setHours(24 * ((data[i].dayId === 0 ? 7 : data[i].dayId) - 1));

            if (
                data[i].hours.end === 0 ||
                Utils.GetDaysTotal(date) <
                    Utils.GetDaysTotal(nowDate.current) ||
                (userData.clientsCanBookDays !== 0 &&
                    Utils.GetDaysTotal(date) >
                        Utils.GetDaysTotal(nowDate.current) +
                            userData.clientsCanBookDays)
            ) {
                addEvent(
                    dayOfTheWeek,
                    date,
                    tableStartMin,
                    tableEndMin,
                    EventType.eBreak
                );
            } else {
                let freeTimeStart = tableStartMin,
                    freeTimeEnd = tableEndMin;

                if (data[i].hours.start > tableStartMin) {
                    freeTimeStart = data[i].hours.start;
                    addEvent(
                        dayOfTheWeek,
                        date,
                        tableStartMin,
                        data[i].hours.start,
                        EventType.eBreak
                    );
                }
                if (data[i].hours.end < tableEndMin) {
                    freeTimeEnd = data[i].hours.end;
                    addEvent(
                        dayOfTheWeek,
                        date,
                        data[i].hours.end,
                        tableEndMin,
                        EventType.eBreak
                    );
                }

                if (
                    Utils.GetDaysTotal(date) ===
                        Utils.GetDaysTotal(nowDate.current) &&
                    getBookingStartTime() > freeTimeStart
                ) {
                    addEvent(
                        dayOfTheWeek,
                        date,
                        freeTimeStart,
                        getBookingStartTime(),
                        EventType.eBreak
                    );

                    if (getBookingStartTime() >= freeTimeEnd)
                        // In case we are out of free time this day
                        continue;

                    freeTimeStart = getBookingStartTime();
                }

                freeTimeOfTheWeek.push({
                    date,
                    timeStartMin: freeTimeStart,
                    timeEndMin: freeTimeEnd,
                });

                if (data[i].hours.breaks !== undefined)
                    for (let j = 0; j < data[i].hours.breaks!.length; ++j) {
                        if (data[i].hours.breaks![j].end <= freeTimeStart)
                            continue;

                        const startMin =
                            data[i].hours.breaks![j].start >= freeTimeStart
                                ? data[i].hours.breaks![j].start
                                : freeTimeStart;
                        addEvent(
                            dayOfTheWeek,
                            date,
                            startMin,
                            data[i].hours.breaks![j].end,
                            EventType.eBreak
                        );
                        addFreeTimeBreak(
                            freeTimeOfTheWeek,
                            date,
                            startMin,
                            data[i].hours.breaks![j].end
                        );
                    }
            }
        }
        return newEvents;
    };

    const loadAgenda = async (
        newEvents: Events,
        freeTime: FreeTimeMap,
        data: WeekItem[]
    ): Promise<Events> => {
        for (let i = 0; i < data.length; ++i) {
            if (
                data[i].timestartMin / 60 < timeTableStart.current &&
                data[i].timeendMin / 60 <= timeTableStart.current
            )
                continue;

            if (data[i].timestartMin / 60 >= timeTableEnd.current) continue;

            let date = new Date(data[i].date);
            let dayOfTheWeek: Event[] = getEventDay(
                newEvents,
                date.getDay()
            ) as Event[];
            let freeTimeOfTheWeek: FreeTime[] = getEventDay(
                freeTime,
                date.getDay()
            ) as FreeTime[];

            if (
                Utils.GetDaysTotal(date) >=
                    Utils.GetDaysTotal(nowDate.current) &&
                (userData.clientsCanBookDays === 0 ||
                    Utils.GetDaysTotal(date) <=
                        Utils.GetDaysTotal(nowDate.current) +
                            userData.clientsCanBookDays)
            ) {
                addEvent(
                    dayOfTheWeek,
                    date,
                    data[i].timestartMin,
                    data[i].timeendMin,
                    EventType.eBusy
                );

                addFreeTimeBreak(
                    freeTimeOfTheWeek,
                    date,
                    data[i].timestartMin,
                    data[i].timeendMin
                );
            }
        }
        return newEvents;
    };

    const setReserveItems = async (
        newEvents: Events,
        freeTime: FreeTimeMap,
        workingHoursData: WorkingHoursDay[]
    ): Promise<Events> => {
        for (let i = 0; i < 7; ++i) {
            let dayOfTheWeek: Event[] = getEventDay(newEvents, i) as Event[];
            let freeTimeOfTheWeek: FreeTime[] = getEventDay(
                freeTime,
                i
            ) as FreeTime[];
            let workingHours = workingHoursData.filter(
                (day) => day.dayId === i
            )[0];
            let date = new Date(weekStart.current);
            date.setHours(24 * ((i === 0 ? 7 : i) - 1));

            for (let j = 0; j < freeTimeOfTheWeek.length; ++j) {
                let timeStart = freeTimeOfTheWeek[j].timeStartMin;

                // Make sure we don't break expected order of booking slots
                // if we are starting not from the day beginning
                let nextExpectedBookingSlot = workingHours.hours.start;
                while (timeStart > nextExpectedBookingSlot)
                    nextExpectedBookingSlot += userData.reserveStepMin;
                timeStart = nextExpectedBookingSlot;

                let reservationWindow =
                    freeTimeOfTheWeek[j].timeEndMin -
                    freeTimeOfTheWeek[j].timeStartMin -
                    getTotalProcedureDuration();
                reservationWindow =
                    reservationWindow > 0
                        ? Math.floor(
                              reservationWindow / userData.reserveStepMin
                          )
                        : reservationWindow;

                while (reservationWindow > 0) {
                    if (
                        timeStart +
                            userData.reserveStepMin +
                            getTotalProcedureDuration() <=
                        freeTimeOfTheWeek[j].timeEndMin
                    )
                        addEvent(
                            dayOfTheWeek,
                            date,
                            timeStart,
                            timeStart + userData.reserveStepMin,
                            EventType.eFree
                        );
                    else {
                        // Last record
                        addEvent(
                            dayOfTheWeek,
                            date,
                            timeStart,
                            freeTimeOfTheWeek[j].timeEndMin,
                            EventType.eFree
                        );
                    }
                    timeStart += userData.reserveStepMin;
                    reservationWindow--;
                }

                if (
                    reservationWindow === 0 &&
                    timeStart + getTotalProcedureDuration() <=
                        freeTimeOfTheWeek[j].timeEndMin
                ) {
                    addEvent(
                        dayOfTheWeek,
                        date,
                        timeStart,
                        freeTimeOfTheWeek[j].timeEndMin,
                        EventType.eFree
                    );
                }
            }
        }
        return newEvents;
    };

    const hasEvents = (dayOfTheWeek: Event[]): boolean => {
        for (let i = 0; i < dayOfTheWeek.length; ++i) {
            if (Number(dayOfTheWeek[i].type) === EventType.eFree) {
                return true;
            }
        }
        return false;
    };

    const deleteEmptyDays = (newEvents: Events) => {
        if (newEvents.monday !== undefined && !hasEvents(newEvents.monday))
            delete newEvents.monday;
        if (newEvents.tuesday !== undefined && !hasEvents(newEvents.tuesday))
            delete newEvents.tuesday;
        if (
            newEvents.wednesday !== undefined &&
            !hasEvents(newEvents.wednesday)
        )
            delete newEvents.wednesday;
        if (newEvents.thursday !== undefined && !hasEvents(newEvents.thursday))
            delete newEvents.thursday;
        if (newEvents.friday !== undefined && !hasEvents(newEvents.friday))
            delete newEvents.friday;
        if (newEvents.saturday !== undefined && !hasEvents(newEvents.saturday))
            delete newEvents.saturday;
        if (newEvents.sunday !== undefined && !hasEvents(newEvents.sunday))
            delete newEvents.sunday;
    };

    const loadItems = async (fromConfirmDialog: boolean = false) => {
        // Keep all data from previous render to avoid
        // "Error: Rendered fewer hooks than expected."
        if (fromConfirmDialog) {
            setIsLoading(false);
            return;
        }

        let newEvents: Events = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        };
        let freeTime: FreeTimeMap = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        };

        let workingHoursResp = await Network.Get(`userworkinghours`);
        if (workingHoursResp.status === 200) {
            newEvents = await loadWorkingHours(
                newEvents,
                freeTime,
                workingHoursResp.data
            );
            let weekResp = await Network.Get(
                `getweek/${encodeURIComponent(
                    Utils.FormatDateForTransfer(weekStart.current)
                )}&${encodeURIComponent(
                    Utils.FormatDateForTransfer(weekEnd.current)
                )}`
            );
            if (weekResp.status === 200) {
                newEvents = await loadAgenda(
                    newEvents,
                    freeTime,
                    weekResp.data
                );

                newEvents = await setReserveItems(
                    newEvents,
                    freeTime,
                    workingHoursResp.data
                );

                deleteEmptyDays(newEvents);

                if (Object.keys(newEvents).length === 0) {
                    // If nothing to display
                    if (
                        Utils.GetDaysTotal(currentDate) ===
                        Utils.GetDaysTotal(nowDate.current)
                    ) {
                        handleSideButtonClick(false);
                        return;
                    } else {
                        newEvents.monday = [];
                        newEvents.tuesday = [];
                        newEvents.wednesday = [];
                        newEvents.thursday = [];
                        newEvents.friday = [];
                    }
                }

                setEvents(newEvents);
                setIsLoading(false);
            }
        }
    };

    React.useEffect(() => {
        constructor();
    });

    const getDayLabel = (day: string) => {
        let date: Date = new Date(weekStart.current);
        switch (day) {
            case "monday":
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[1]
                } ${Utils.GetDateMonthString(date)}`;
            case "tuesday":
                date.setHours(24 * 1);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[2]
                } ${Utils.GetDateMonthString(date)}`;
            case "wednesday":
                date.setHours(24 * 2);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[3]
                } ${Utils.GetDateMonthString(date)}`;
            case "thursday":
                date.setHours(24 * 3);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[4]
                } ${Utils.GetDateMonthString(date)}`;
            case "friday":
                date.setHours(24 * 4);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[5]
                } ${Utils.GetDateMonthString(date)}`;
            case "saturday":
                date.setHours(24 * 5);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[6]
                } ${Utils.GetDateMonthString(date)}`;
            case "sunday":
                date.setHours(24 * 6);
                return `${
                    LocaleConfig.locales[LocaleConfig.defaultLocale]
                        .dayNamesShort[0]
                } ${Utils.GetDateMonthString(date)}`;
        }
        return day;
    };

    const backToMenu = (doUpdate: boolean) => {
        setCurrentAnimation(animationStyles.fadeOut);
        setTimeout(() => {
            onClose(doUpdate);
        }, 500);
    };

    const sideBoxSx = { width: 20 };
    const handleSideButtonClick = (isLeft: boolean) => {
        const newDate = new Date(currentDate.getTime());

        if (isLeft) {
            newDate.setHours(-24 * 7);
        } else {
            newDate.setHours(24 * 7);
        }

        let newWeekStart = new Date(newDate);
        let day = newWeekStart.getDay() || 7;
        if (day !== 1) newWeekStart.setHours(-24 * (day - 1));

        if (
            Utils.GetDaysTotal(newDate) >=
                Utils.GetDaysTotal(nowDate.current) &&
            (userData.clientsCanBookDays === 0 ||
                Utils.GetDaysTotal(newWeekStart) <=
                    Utils.GetDaysTotal(nowDate.current) +
                        userData.clientsCanBookDays)
        ) {
            if (isMobile) {
                setCurrentDate(newDate);
                hasBeenCalled.current = false; // Re-call constructor
            } else {
                setCurrentAnimation(animationStyles.fadeOut);
                setTimeout(() => {
                    setCurrentDate(newDate);
                    hasBeenCalled.current = false; // Re-call constructor
                }, 500);
            }
        }
    };

    const swipeHandlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.absX > window.innerWidth / 4) {
                if (eventData.deltaX < 0) {
                    handleSideButtonClick(false);
                } else {
                    handleSideButtonClick(true);
                }
            }
            setLeftButtonPosition(sliderButtonInitialPos);
            setRightButtonPosition(sliderButtonInitialPos);
            setUpdateButton(false);
        },
        onSwiping: (eventData) => {
            if (
                (eventData.absX > window.innerWidth / 8 &&
                    eventData.absY < window.innerHeight / 8) ||
                leftButtonPosition !== sliderButtonInitialPos ||
                rightButtonPosition !== sliderButtonInitialPos
            ) {
                if (eventData.deltaX < 0) {
                    // Right
                    if (eventData.absX < window.innerWidth / 4) {
                        setRightButtonPosition(eventData.absX);
                        if (updateButton) setUpdateButton(false);
                    } else {
                        setRightButtonPosition(window.innerWidth / 4);
                        if (!updateButton) setUpdateButton(true);
                    }
                } else {
                    // Left
                    if (eventData.absX < window.innerWidth / 4) {
                        setLeftButtonPosition(eventData.absX);
                        if (updateButton) setUpdateButton(false);
                    } else {
                        setLeftButtonPosition(window.innerWidth / 4);
                        if (!updateButton) setUpdateButton(true);
                    }
                }
            }
        },
    });

    return (
        <>
            <Backdrop
                open={true}
                style={{
                    backgroundColor: `${userUITheme.palette.secondary.main}DD`,
                }}
            >
                {!isMobile && <CircularProgress color="primary" />}
            </Backdrop>
            {isLoading ? (
                <></>
            ) : (
                <Box display="flex" {...swipeHandlers}>
                    {!isMobile ? (
                        <Box sx={sideBoxSx}>
                            <SideButton
                                handleClick={handleSideButtonClick}
                                isLeft={true}
                            />
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box
                        flexGrow={1}
                        style={{
                            position: "relative",
                        }}
                        className={css(currentAnimation)}
                    >
                        <TimeTable
                            dimensions={dimensions}
                            events={events}
                            renderEvent={renderEvent}
                            getDayLabel={getDayLabel}
                            cornerButtonCallback={() => backToMenu(false)}
                            hoursInterval={{
                                from: timeTableStart.current,
                                to: timeTableEnd.current,
                            }}
                        />
                    </Box>
                    {!isMobile ? (
                        <Box sx={sideBoxSx}>
                            <SideButton
                                handleClick={handleSideButtonClick}
                                isLeft={false}
                            />
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            )}

            {updateButton ? (
                <CachedOutlined
                    fontSize={"large"}
                    className={
                        isSafari
                            ? classNames.sliderButtonSafari
                            : classNames.sliderButton
                    }
                    style={{
                        left: leftButtonPosition,
                    }}
                />
            ) : (
                <ArrowBackOutlined
                    fontSize={"large"}
                    className={
                        isSafari
                            ? classNames.sliderButtonSafari
                            : classNames.sliderButton
                    }
                    style={{
                        left: leftButtonPosition,
                    }}
                />
            )}

            {updateButton ? (
                <CachedOutlined
                    fontSize={"large"}
                    className={
                        isSafari
                            ? classNames.sliderButtonSafari
                            : classNames.sliderButton
                    }
                    style={{
                        right: rightButtonPosition,
                    }}
                />
            ) : (
                <ArrowForwardOutlined
                    fontSize={"large"}
                    className={
                        isSafari
                            ? classNames.sliderButtonSafari
                            : classNames.sliderButton
                    }
                    style={{
                        right: rightButtonPosition,
                    }}
                />
            )}

            <ConfirmDialog
                dimensions={dimensions}
                autoFillName={autoFillName}
                autoFillMobile={autoFillMobile}
                backToMenu={backToMenu}
                startTime={selectedEvent.current?.startTime!}
                menuItems={menuItems}
                additionalServices={additionalServices}
                replaceAgendaItem={replaceAgendaItem}
                isOpen={showConfirmDialog}
                handleClose={(updateTimeTable: boolean) => {
                    selectedEvent.current = undefined;
                    setShowConfirmDialog(false);
                    if (updateTimeTable) {
                        loadItems(true);
                    }
                }}
                userData={userData}
                bookingRulesItems={bookingRulesItems}
                discountCampaignItems={discountCampaignItems}
                updateDiscountsData={updateDiscountsData}
                updatePromoCode={updatePromoCode}
                updateSelectedDiscountCampaign={updateSelectedDiscountCampaign}
                selectedDiscountCampaignItemId={selectedDiscountCampaignItemId}
                promoCode={promoCode}
                promoCodeInfoText={promoCodeInfoText}
                selectedDiscountCampaignItem={selectedDiscountCampaignItem}
            />
        </>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { slideInUp, slideOutDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { Dimensions } from "../style/theme";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { BookingRulesItem } from "visit-shared";

const animationStyles = StyleSheet.create({
    slideInUp: {
        animationName: slideInUp,
        animationDuration: "0.5s",
    },
    slideOutDown: {
        animationName: slideOutDown,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        height: 56,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    emptyTitle: {
        flex: 1,
    },
    background: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
    },
    card: {
        textAlign: "center",
        margin: 20,
        borderRadius: 20,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white,
    },
    text: {
        textAlign: "left",
        color: theme.palette.text.primary,
        whiteSpace: "pre-line",
    },
}));

interface BookingRulesInfoDialogProps {
    dimensions: Dimensions;
    isOpen: boolean;
    handleClose: any;
    selectedItem: BookingRulesItem | null;
}

export default function BookingRulesInfoDialog({
    dimensions,
    isOpen,
    handleClose,
    selectedItem,
}: BookingRulesInfoDialogProps) {
    const classes = useStyles();
    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.slideInUp
    );

    return (
        <div>
            {/* TODO: Fix Warning: findDOMNode is deprecated in StrictMode. */}
            <Modal open={isOpen} className={css(currentAnimation)}>
                <div className={classes.background}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <div className={classes.emptyTitle} />
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setCurrentAnimation(
                                        animationStyles.slideOutDown
                                    );
                                    setTimeout(() => {
                                        handleClose();
                                        setCurrentAnimation(
                                            animationStyles.slideInUp
                                        );
                                    }, 500);
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {/* Height adjustment is calculated based on app bar height */}
                    <div
                        style={{
                            height: dimensions.height - 56,
                            overflow: "auto",
                        }}
                    >
                        <Card className={classes.card}>
                            <CardHeader
                                titleTypographyProps={{
                                    style: { fontWeight: "bold" },
                                }}
                                subheaderTypographyProps={{
                                    fontWeight: "bold",
                                }}
                                disableTypography={false}
                                title={selectedItem?.name}
                            />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    className={classes.text}
                                >
                                    {selectedItem?.text}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Dimensions } from "../style/theme";
import BookingRulesInfoDialog from "./BookingRulesInfoDialog";
import { BookingRulesItem } from "visit-shared";

const useStyles = makeStyles((theme) => ({
    bookingRulesView: {
        width: "100%",
    },
    bookingRulesRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },
    bookingRulesLinkButton: {
        margin: "3px",
        textTransform: "none",
        whiteSpace: "nowrap",
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.secondary.main,
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "2px",
        "&:hover, &.Mui-focusVisible": {
            textDecoration: "underline",
            textUnderlineOffset: "5px",
            textDecorationThickness: "2px",
        },
    },
}));

interface BookingRulesViewProps {
    dimensions: Dimensions;

    bookingRulesItems: BookingRulesItem[];
}

export default function BookingRulesView({
    dimensions,
    bookingRulesItems,
}: BookingRulesViewProps) {
    const classes = useStyles();

    const [selectedBookingRulesItem, setSelectedBookingRulesItem] =
        React.useState<BookingRulesItem | null>(null);
    const [showBookingRulesInfoDialog, setShowBookingRulesInfoDialog] =
        React.useState(false);

    return bookingRulesItems.length > 0 ? (
        <div className={classes.bookingRulesView}>
            <div className={classes.bookingRulesRow}>
                {bookingRulesItems.map((item) => (
                    <Button
                        key={item.id}
                        variant="text"
                        className={classes.bookingRulesLinkButton}
                        onClick={() => {
                            setSelectedBookingRulesItem(item);
                            setShowBookingRulesInfoDialog(true);
                        }}
                    >
                        {item.name}
                    </Button>
                ))}
            </div>
            <BookingRulesInfoDialog
                dimensions={dimensions}
                isOpen={showBookingRulesInfoDialog}
                selectedItem={selectedBookingRulesItem}
                handleClose={() => setShowBookingRulesInfoDialog(false)}
            />
        </div>
    ) : null;
}
